window.$docsify = {
    basepath: '/',
    name: 'Datapoort documentatie',
    logo: 'images/datapoort-duotone-kleur-wit-logo.svg',
    homepage: 'home.md',
    loadSidebar: true,
    relativePath: true,
    search: {
        // maxAge: 86_400_000,
        maxAge: 30_000, // todo after content is filled, change back to 86400000 (one day in ms)
        // hideOtherSidebarContent: false,
        placeholder: 'Zoeken naar ..',
        depth: 6,
        noData: {
            '/': 'Geen resultaten'
        }
    },
    tabs: {
        persist    : true,      // default
        sync       : true,      // default
        theme      : 'classic', // default
        tabComments: true,      // default
        tabHeadings: true       // default
    }
}


