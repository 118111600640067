/**
 * Declare Vue components here, not in markdown files. Otherwise the <script /> content can show up in search results
 */
function vueComponents(hook, /*vm*/) {
    // eslint-disable-next-line
    hook.doneEach(function() {
        Vue.createApp({
            data() {
                return {
                    name: 'Naam.'
                }
            }
        }).mount('#home_main');
    })
}

if (window) {
    window.$docsify.plugins = [].concat(vueComponents, window.$docsify.plugins);
}
