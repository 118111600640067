function tweaksPlugin(hook, /*vm*/) {
    // eslint-disable-next-line
    hook.init(function () {
        const $search = Docsify.dom.find('div.search');
        const $input = Docsify.dom.find($search, 'input');
        Docsify.dom.on($input, 'input', function (e) {
            if (e.target.value?.length > 0 && e.target.value?.length < 3) {
                e.stopImmediatePropagation()
            }
        });
        // todo find out if original docsify search.placeholder string (defined in /develop/main.js) is exposed and use that instead of hardcoded copy here
        Docsify.dom.on($input, 'focusin', function (e) {
            e.target.placeholder = 'Zoeken naar ..  (min. 3 karakters)'
        });
        Docsify.dom.on($input, 'focusout', function (e) {
            e.target.placeholder = 'Zoeken naar ..'
        });
    });
}

if (window) {
    window.$docsify.plugins = [].concat(tweaksPlugin, window.$docsify.plugins);
}
