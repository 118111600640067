function breadcrumbPlugin(hook, vm) {
    // eslint-disable-next-line
    hook.afterEach(function (html, next) {
        let pageHasBreadcrumb = true;
        const url = vm.route.file;
        const parts = url.split("/");
        let breadcrumb = "<ul class='breadcrumb'>";
        let currentPath = "#/";
        // no breadcrumb for homepage
        if (parts.length === 1 && parts.slice(-1)[0] === 'home.md') {
            pageHasBreadcrumb = false
        }
        // index.md points to its parent (is effectively the same as parent) so don't include in breadcrumb
        if (parts.length && parts.slice(-1)[0] === 'index.md') {
            parts.pop()
        }
        // always append home as first breadcrumb item
        breadcrumb += "<li><a href='" + currentPath + "'>Home</a></li>";
        parts.forEach(function (part, index) {
            currentPath += part + "/";
            const displayText = part.replace(/\.md$/, "").replace(/[_-]/g, " ");
            const capitalizedText = displayText.charAt(0).toUpperCase() + displayText.slice(1);
            if (index < parts.length - 1) {
                breadcrumb += "<li><a href='" + currentPath + "index'>" + capitalizedText + "</a></li>";
            } else {
                breadcrumb += "<li class='active'>" + capitalizedText + "</li>";
            }
        });
        breadcrumb += "</ul>";
        if (pageHasBreadcrumb === true) {
            next(breadcrumb + html);
        } else {
            next(html);
        }
    });
}

if (window) {
    window.$docsify.plugins = [].concat(breadcrumbPlugin, window.$docsify.plugins);
}
